import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {generatePath, Link, useParams} from 'react-router-dom';
import Showdown from 'showdown';
import {getCourseByURL} from '../../../redux/actions/courses';
import Spinner from '../Utils/Spinner';
import ApiError from '../ApiError';
import EmptyMessage from '../Utils/EmptyMessage';
import PreviousPageButton from './previousPageButton';
import NextPageButton from './nextPageButton';
import titleToUrl from '../Utils/titleToUrl';
import NetworkImage from '../../components/NetworkImage';
import ROUTES from '../../../routes';
import _ from 'lodash';
import MetaHemlet from '../../components/MetaHemlet';

const Course = function() {
  const converter = new Showdown.Converter();
  const {url, sectionSlug, pageIndex} = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [course, setCourse] = useState(null);
  const [section, setSection] = useState(null);
  const [page, setPage] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [firstPageId, setFirstPageId] = useState('');
  const [lastPageId, setLastPageId] = useState('');

  const sortCourseBySectionsAndPages = courseObj => {
    if (courseObj.sections) {
      courseObj.sections = _.sortBy(courseObj.sections, 'index');
      courseObj.sections.forEach(sectionObj => {
        if (sectionObj.pages) {
          sectionObj.pages = _.sortBy(sectionObj.pages, 'index');
        }
      });
    }

    return courseObj;
  };

  useEffect(() => {
    const findFirsTAndLastPageId = () => {
      let firstIdFound = false;
      let lastIdFound = false;

      for (let i = 0; i < course.sections.length; i++) {
        if (!firstIdFound && course.sections[i].pages.length > 0) {
          setFirstPageId(course.sections[i].pages[0].id);
          firstIdFound = true;
        }
      }

      for (let i = course.sections.length - 1; i >= 0; i--) {
        if (!lastIdFound && course.sections[i].pages.length > 0) {
          const sectionPages = course.sections[i].pages;
          setLastPageId(sectionPages[sectionPages.length - 1].id);
          lastIdFound = true;
        }
      }
    };

    if (course === null) {
      return;
    }

    let selectedSection = null;

    if (course.sections.length > 0) {
      if (sectionSlug) {
        selectedSection = course.sections.find(sec => titleToUrl(sec.title) === sectionSlug);
      }
      else {
        selectedSection = course.sections[0];
      }
    }

    setSection(selectedSection);
    findFirsTAndLastPageId();
    setActiveTab(0);
  }, [course, sectionSlug]);

  useEffect(() => {
    if (section === null) {
      setPage(null);
      return;
    }

    let selectedPage = null;

    if (section.pages.length > 0) {
      if (pageIndex) {
        selectedPage = section.pages[pageIndex - 1];
      }
      else {
        selectedPage = section.pages[0];
      }
    }

    setPage(selectedPage);
    setActiveTab(0);
  }, [section, pageIndex]);

  useEffect(() => {
    dispatch(getCourseByURL(url))
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setCourse(sortCourseBySectionsAndPages(action.payload.data));
          setError(null);
        }
      })
      .catch(err => setError(err.getMessage()));
  }, [dispatch, url]);

  const courseContent = () => {
    if (section === null) {
      return <div className='margin-1'>
        <EmptyMessage
          alignCenter
          message={`There are no sections to show for ${course.title}.`}/>
      </div>;
    }

    return <div className={section.title}>
      {page ? <div className='course-content'>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(page.content)}}/>
        <div className='grid-x buttons'>
          {page.id !== firstPageId && <div className='cell shrink'>
            <PreviousPageButton
              course={course}
              page={page}
              section={section}/>
          </div>}
          <div className='cell auto'/>
          {page.id !== lastPageId && <div className='cell shrink'>
            <NextPageButton
              course={course}
              page={page}
              section={section}/>
          </div>}
        </div>
      </div>
        : <div className='margin-1'>
          <EmptyMessage
            alignCenter
            message={`There are no pages to show for ${section.title}.`}/></div>}
    </div>;
  };

  const sidebar = () => <ul className='sections-list vertical menu'>
    {course.sections.map(sec => {
      const isCurrentSection = sec.id === section.id;
      const sectionClass = isCurrentSection ? 'selected' : '';

      return (
        <React.Fragment key={`section_${sec.id}`}>
          <li
            className={`section ${sectionClass}`}
            onClick={() => setActiveTab(0)}>
            <Link
              to={generatePath(ROUTES.COURSE_DETAILS,
                {url: course.url, sectionSlug: titleToUrl(sec.title), pageIndex: 1})}>
              {sec.title}
            </Link>
          </li>
          {isCurrentSection && <ul className='nested vertical menu'>
            {page && sec.pages.map((pa, index) => {
              const isCurrentPage = pa.id === page.id;
              const pageClass = isCurrentPage ? 'selected' : '';
              return (
                <li
                  className={`page ${pageClass}`}
                  key={`page_${pa.id}`}
                  onClick={() => setActiveTab(0)}>
                  <Link
                    to={generatePath(ROUTES.COURSE_DETAILS,
                      {url: course.url, sectionSlug: titleToUrl(sec.title), pageIndex: index + 1})}>
                    {pa.title}
                  </Link>
                </li>
              );
            })}
          </ul>}
        </React.Fragment>
      );
    })}
  </ul>;

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (course === null) {
    return <header><Spinner/></header>;
  }

  return <div className='bg'>
    <MetaHemlet
      description={course.description}
      title={`${course.title} ${section ? ' - ' && section.title : ''} ${page ? ' - ' && page.title : ''}`}/>
    <div className='course gradient-bg'>
      <NetworkImage
        format='large'
        header
        image={course.image}>
        <div className='h1'>{course.title}</div>
      </NetworkImage>
      <div className='grid-container show-for-medium'>
        <div className='grid-x grid-padding-x align-stretch align-center'>
          {section && <div className='cell medium-4 large-3 sidebar'>
            <div className='grid-y grid-margin-y grid-margin-x'>
              <div className='cell'>
                {sidebar()}
              </div>
            </div>
          </div>}
          <div className='cell medium-8 large-6 grid-padding-y grid-padding-x'>
            {courseContent()}
          </div>
          {
            page &&
            <div className='cell medium-4 large-3 sidebar'>
              {
                page.sidebar &&
                <div className='cell course-sidebar padding-top-2'>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{__html: converter.makeHtml(page.sidebar)}}/>
                </div>
              }
              {
                course.sidebar &&
                <div className='cell course-sidebar padding-top-2'>
                  <h2 className='teal-color font-bold h4'>Additional content</h2>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{__html: converter.makeHtml(course.sidebar)}}/>
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div className='grid-container hide-for-medium'>
        <ul
          className='tabs'>
          <li className={`tabs-title ${activeTab === 0 ? 'is-active' : ''}`}>
            <button
              onClick={() => setActiveTab(0)}
              type='button'
            >MODULE</button>
          </li>
          {section &&
          <li className={`tabs-title ${activeTab === 1 ? 'is-active' : ''}`}>
            <button
              onClick={() => setActiveTab(1)}
              type='button'
            >INDEX</button>
          </li>
          }
          {
            course.sidebar &&
            <li className={`tabs-title ${activeTab === 2 ? 'is-active' : ''}`}>
              <button
                onClick={() => setActiveTab(2)}
                type='button'
              >Additional content</button>
            </li>
          }
        </ul>
        <div className='tabs-content'>
          <div
            className={`tabs-panel ${activeTab === 0 ? 'is-active' : ''}`}>
            <div className='cell small-12 large-8 grid-padding-y grid-padding-x'>
              {courseContent()}
            </div>
          </div>
          {
            section &&
            <div
              className={`tabs-panel ${activeTab === 1 ? 'is-active' : ''}`}>
              {sidebar()}
            </div>
          }
          {
            course.sidebar &&
            <div
              className={`tabs-panel ${activeTab === 2 ? 'is-active' : ''}`}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{__html: converter.makeHtml(course.sidebar)}}/>
            </div>
          }
        </div>
      </div>
    </div>
  </div>;
};

export default Course;
