import React, {useEffect, useState} from 'react';
import Background from '../../../assets/images/headers/education.jpg';
import {getUrgentResources} from '../../../redux/actions/articles';
import {useDispatch} from 'react-redux';
import Showdown from 'showdown';
import ApiError from '../ApiError';
import Spinner from '../Utils/Spinner';
import MetaHemlet from '../../components/MetaHemlet';

const ResourcesScreen = function() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [urgentResource, setUrgentResource] = useState(null);
  const converter = new Showdown.Converter();

  useEffect(() => {
    dispatch(getUrgentResources())
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setUrgentResource(action.payload.data.data);
        }
      });
  }, [dispatch]);

  const urgentResourceContent = () => <>
    {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(urgentResource.content)}}/>
  </>;

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (urgentResource === null) {
    return <header><Spinner/></header>;
  }

  return <>
    <MetaHemlet
      description='If you are experiencing a mental health crisis call your local crisis line or go to the
       nearest emergency department.'
      title='Urgent Resources'
    />
    <header style={{backgroundImage: `url(${Background})`}}>
      <h1>Crisis Line</h1>
    </header>
    <section className='gradient-bg'>
      <div className='grid-container width-100 text-left'>
        <div className='grid-x align-center'>
          <div className='cell medium-10 large-8'>
            <h1>Crisis Line</h1>
            <div>
              {urgentResourceContent() === null ? 'Research Not available at this moment please try again later'
                : urgentResourceContent()}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default ResourcesScreen;
