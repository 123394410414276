import React, {useState, useEffect} from 'react';
import ApiError from '../ApiError';
import Spinner from '../Utils/Spinner';
import {useDispatch} from 'react-redux';
import Showdown from 'showdown';
import {getAboutData} from '../../../redux/actions/articles';
import MetaHemlet from '../../components/MetaHemlet';

const Index = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const converter = new Showdown.Converter();

  useEffect(() => {
    dispatch(getAboutData())
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setAboutData(action.payload.data.data);
        }
      });
  }, [dispatch]);

  const aboutContent = () => <>
    <div>
      {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(aboutData.content)}}/>
    </div>
  </>;

  if (error) {
    return <ApiError error={error}/>;
  }

  else if (aboutData === null) {
    return <header><Spinner/></header>;
  }

  return (
    <>
      <MetaHemlet
        description='About hope'
        title='About'
      />
      <header>
        <div className='h1'>About</div>
      </header>
      <section className='gradient-bg'>
        <div className='grid-container'>
          <h1>A little bit about HOPE</h1>
          <div>
            {aboutContent() === null ? 'Not available at this moment please try again later'
              : aboutContent()}
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
