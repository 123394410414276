import React, {useEffect, useState} from 'react';
import Background from '../../../assets/images/headers/education.jpg';
import {getResearch} from '../../../redux/actions/articles';
import {useDispatch} from 'react-redux';
import Showdown from 'showdown';
import ApiError from '../ApiError';
import Spinner from '../Utils/Spinner';

const ResearchScreen = function() {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [research, setresearch] = useState(null);
  const converter = new Showdown.Converter();

  useEffect(() => {
    dispatch(getResearch())
      .then(action => {
        if (action.error) {
          setError(action.error);
        }
        else {
          setresearch(action.payload.data.data);
        }
      });
  }, [dispatch]);

  const researchContent = () => <>
    {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{__html: converter.makeHtml(research.content)}}/>
  </>;

  if (error) {
    return <ApiError error={error}/>;
  }
  else if (research === null) {
    return <header><Spinner/></header>;
  }

  return <>
    <header style={{backgroundImage: `url(${Background})`}}>
      <h1>Research</h1>
    </header>
    <section>
      <div className='grid-container width-100 text-left'>
        <div className='grid-x align-center'>
          <div className='cell medium-10 large-8'>
            <h1>Research</h1>
            <div>
              {researchContent() === null ? 'Research Not available at this moment please try again later.'
                : researchContent()}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default ResearchScreen;
